import classes from './Services.module.scss';
import sw from 'assets/images/cards/software.png';
import dt from 'assets/images/cards/digital_twin.png';
import iot from 'assets/images/cards/iot_sensors.png';
import ps from 'assets/images/cards/project_service.png';
import rd from 'assets/images/cards/r_and_d.png';
import se from 'assets/images/cards/shared_energy.png';
import { Arrow } from 'assets/icons/Arrow';
import { ArrowB } from 'assets/icons/ArrowB';
import Divider from 'components/divider/Divider';
import { useTranslation } from 'react-i18next';

type Card = {
    title: string;
    bg: string;
    link?: string;
    black?: boolean;
};

const Services = () => {
    const { t } = useTranslation('services');

    const cards: Card[] = [
        {
            title: t('cardTitle1'),
            bg: sw,
            link: 'https://www.eagleprojects.it/en/software/'
        },
        {
            title: t('cardTitle2'),
            bg: dt,
            link: 'https://www.eagleprojects.it/en/digital-twin/'
        },
        {
            title: t('cardTitle3'),
            bg: iot,
            link: 'https://www.eagleprojects.it/en/sensori-iot/'
        },
        {
            title: t('cardTitle4'),
            bg: ps,
            link: 'https://www.eagleprojects.it/en/project-service/'
        },
        {
            title: t('cardTitle5'),
            bg: se,
            link: 'https://www.eagleprojects.it/en/energia-condivisa/'
        },
        {
            title: t('cardTitle6'),
            bg: rd,
            link: 'https://www.eagleprojects.it/en/ricerca-e-sviluppo/',
            black: true
        }
    ];

    return (
        <section className={classes.container}>
            <div className={classes.content}>
                <h3>{t('title')}</h3>
                <Divider />
                <div className={classes.cardsContainer}>
                    {cards.map((card, index) => (
                        <div
                            className={classes.card}
                            onClick={() => window.open(card.link, '_blank')}
                            key={`${index}_${card.title}`}
                            style={{ backgroundImage: `url(${card.bg})` }}
                        >
                            <h4 className={card.black ? '' : 'white'}>{card.title}</h4>
                            <div className={classes.button}>{card.black ? <ArrowB /> : <Arrow />}</div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Services;
