import Divider from 'components/divider/Divider';
import classes from './Company.module.scss';
import bgCompany from '../../../assets/images/bg_company.png';
import { useTranslation } from 'react-i18next';

const Company = () => {
    const { t } = useTranslation('company');

    return (
        <section className={classes.container}>
            <div className={classes.content}>
                <h3>{t('title')}</h3>
                <Divider />
                <div className={classes.gridContainer}>
                    <div className={classes.blockOne}>
                        <img src={bgCompany} alt="bg_company" />
                    </div>
                    <div className={classes.blockTwo}>
                        <div>
                            <p>
                                {t('par1')}&nbsp;
                                <a target="_blank" rel="noreferrer" href={'https://www.eagleprojects.it'}>
                                    Eagleprojects S.p.A.
                                </a>
                                {t('par1.1')}
                            </p>
                        </div>
                        <div>
                            <h4 className="blue">2024</h4>
                            <p>{t('par2')}</p>
                        </div>
                        <div>
                            <h4 className="blue">{t('subtitle1')}</h4>
                            <p>{t('par3')}</p>
                        </div>
                        <div>
                            <h4 className="blue">{t('subtitle3')}</h4>
                            <p>
                                {t('par4')}&nbsp;
                                <a target="_blank" rel="noreferrer" href={'https://www.eagleprojects.it'}>
                                    Eagleprojects S.p.A.
                                </a>
                                {t('par4.1')}
                            </p>
                        </div>
                    </div>
                    <div className={classes.blockThree}>
                        <div className={classes.card}>
                            <h3 className="blue">+5500</h3>
                            <p>{t('numPar1')}</p>
                        </div>
                        <div className={classes.card}>
                            <h3 className="blue">4,8 {t('mln')}</h3>
                            <p>{t('numPar2')}</p>
                        </div>
                        <div className={classes.card}>
                            <h3 className="blue">+5000</h3>
                            <p>{t('numPar3')}</p>
                        </div>
                        <div className={classes.card}>
                            <h3 className="blue">+2 {t('mln')}</h3>
                            <p>{t('numPar4')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Company;
