import classes from './Partners.module.scss';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useTranslation } from 'react-i18next';

const Partners = () => {
    const { t } = useTranslation('partners');
    const partners = [
        { logo: 'logo_a2a' },
        { logo: 'logo_accenture' },
        { logo: 'logo_acea' },
        { logo: 'logo_anas' },
        { logo: 'logo_arcadis' },
        { logo: 'logo_autostrade_per_litalia' },
        { logo: 'logo_circet' },
        { logo: 'logo_cogepa' },
        { logo: 'logo_e_distribuzione' },
        { logo: 'logo_enelx' },
        { logo: 'logo_essential_ark' },
        { logo: 'logo_fibercop' },
        { logo: 'logo_fibraweb' },
        { logo: 'logo_fibre_connect' },
        { logo: 'logo_infratel_italia' },
        { logo: 'logo_inwit' },
        { logo: 'logo_openfiber' },
        { logo: 'logo_public_power_corporation' },
        { logo: 'logo_siat' },
        { logo: 'logo_sielte' },
        { logo: 'logo_sirti' },
        { logo: 'logo_site' },
        { logo: 'logo_solutions30' },
        { logo: 'logo_technosky' },
        { logo: 'logo_telebit' },
        { logo: 'logo_terna' },
        { logo: 'logo_tesmec' },
        { logo: 'logo_tim' },
        { logo: 'logo_unareti' },
        { logo: 'logo_unidata' },
        { logo: 'logo_valtellina' },
        { logo: 'logo_vodafone' }
    ];
    const responsive = {
        desktop: {
            breakpoint: {
                max: 4000,
                min: 1500
            },
            items: 6,
            partialVisibilityGutter: 30,
            slidesToSlide: 5
        },
        tablet: {
            breakpoint: {
                max: 1500,
                min: 900
            },
            items: 4,
            partialVisibilityGutter: 30,
            slidesToSlide: 3
        },
        mobile: {
            breakpoint: {
                max: 900,
                min: 0
            },
            items: 4,
            slidesToSlide: 4
        }
    };

    return (
        <section className={classes.container}>
            <div className={classes.content}>
                <h3>{t('title')}</h3>
                {
                    <div className={classes.carousel}>
                        <Carousel
                            responsive={responsive}
                            centerMode={false}
                            draggable
                            partialVisible
                            infinite
                            keyBoardControl
                            minimumTouchDrag={80}
                            renderDotsOutside={false}
                            arrows={false}
                            showDots
                            swipeable
                            focusOnSelect
                            additionalTransfrom={0}
                        >
                            {partners.map((partner, index) => (
                                <div key={`${index}_${partner.logo}`} className={classes.carouselImgContainer}>
                                    <img
                                        draggable="false"
                                        className={classes.carouselImg}
                                        src={require('../../../assets/images/partners/' + partner.logo + '.png')}
                                        alt={partner.logo}
                                    />
                                </div>
                            ))}
                        </Carousel>
                        <div className={classes.overlay}></div>
                    </div>
                }
            </div>
        </section>
    );
};

export default Partners;
